"use client";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Photos } from "contentlayer/generated";
import Image from "next/image";
import { CoreContent } from "pliny/utils/contentlayer";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLink from "./CustomLink";

interface TileProps {
  photo: CoreContent<Photos>;
}

const Tile = ({ photo }: TileProps) => (
  <div className="group relative">
    <CustomLink href={`/${photo.path}`} title={photo.title} aria-label={photo.title}>
      <div className="invisible absolute z-50 size-full bg-black-500/85 group-hover:visible">
        <div className="flex size-full items-center justify-center text-2xl font-bold leading-8 tracking-tight text-gray-900">
          {photo.title}
        </div>
      </div>
      <Image
        src={photo.images[0]}
        alt={photo.title}
        width={Math.floor(photo.width / 4)}
        height={Math.floor(photo.height / 4)}
        className="pointer-events-none m-0 h-auto max-w-full rounded-sm border-[10px] border-silver-900 hover:shadow-lg hover:brightness-110"
        loading="lazy"
        blurDataURL={photo.base64}
        placeholder="blur"
        quality={50}
      />
    </CustomLink>
  </div>
);

const Loader = () => (
  <div className="w-full p-10">
    <div className="flex items-center justify-center">
      <FontAwesomeIcon icon={faCircleNotch} size="xl" spin />
    </div>
  </div>
);

interface PortfolioProps {
  photos: CoreContent<Photos>[];
}

const photosToTiles = (photos: CoreContent<Photos>[]) =>
  photos.map((p) => <Tile key={p.path} photo={p} />);

export default function Portfolio({ photos }: PortfolioProps) {
  const tilesPerScroll = 5;
  const [tiles, setTiles] = useState(photosToTiles(photos.slice(0, tilesPerScroll)));

  const firstColumn = tiles.filter((_, i) => i % 2 == 0);
  const secondColumn = tiles.filter((_, i) => i % 2 == 1);

  return (
    <>
      <InfiniteScroll
        dataLength={tiles.length}
        next={() => setTiles(photosToTiles(photos.slice(0, tiles.length + tilesPerScroll)))}
        hasMore={tiles.length < photos.length}
        loader={<Loader />}
        scrollThreshold={0.95}
      >
        <div className="grid grid-cols-1 items-start gap-2 md:grid-cols-2">
          {[firstColumn, secondColumn].map((col, i) => (
            <div className="grid justify-items-start gap-2" key={i}>
              {col}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </>
  );
}
